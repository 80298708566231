import { faker } from "@faker-js/faker";
import { Action } from "@remix-run/router";
import produce from "immer";
import { act } from "react-dom/test-utils";
import { call, put, delay, select } from "redux-saga/effects";
import { AppAction } from "../action_template";
import {
    EntitiesResponse,
    EntityTypeResponse,
    ProjectResponse
} from "../api_endpoints/api_response_types";
import EntitiesApi, { UpdateEntityArgs } from "../api_endpoints/entities_api";
import { PromiseStatus, sleep, to } from "../helpers";
import { RootState } from "../redux";
import { UserSlice } from "../user/user_slice";
import { GET_ENTITY_REQUEST } from "./entity_get_action";

export class UPDATE_ENTITY_REQUEST extends AppAction<UpdateEntityArgs> {
    type = "UPDATE_ENTITY_REQUEST";

    constructor(payload: UpdateEntityArgs) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.entity_slice.update_entity_promise = PromiseStatus.pending;
        });
    }

    static *update_entity_saga(action: UPDATE_ENTITY_REQUEST): Generator<any, any, any> {
        const user_slice: UserSlice = yield select((state: RootState) => state.user_slice);

        const [result, error] = yield call(() =>
            to(
                !action.payload?.old_entity
                    ? EntitiesApi.create_entity(
                          user_slice.auth_id!,
                          user_slice.user!,
                          action.payload!
                      )
                    : (EntitiesApi.update_entity(
                          user_slice.auth_id!,
                          user_slice.user!,
                          action.payload!
                      ) as any)
            )
        );
        yield put(new UPDATE_ENTITY_FINISH());

        if (error) {
            yield put(new UPDATE_ENTITY_ERROR());
            return;
        }

        action.payload?.close?.()
        yield put(new GET_ENTITY_REQUEST());
        yield put(new UPDATE_ENTITY_SUCCESS(result));
        return;
    }
}

class UPDATE_ENTITY_ERROR extends AppAction<void> {
    type = "UPDATE_ENTITY_ERROR";

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.entity_slice.update_entity_promise = PromiseStatus.error;
        });
    }
}

class UPDATE_ENTITY_SUCCESS extends AppAction<any> {
    type = "UPDATE_ENTITY_SUCCESS";

    constructor(payload: any) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        const payload = this.payload!;

        return produce(state, (draft) => {});
    }
}

class UPDATE_ENTITY_FINISH extends AppAction<void> {
    type = "UPDATE_ENTITY_FINISH";

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.entity_slice.update_entity_promise = PromiseStatus.idle;
        });
    }
}
