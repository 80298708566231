import produce from "immer";
import { call, put, delay, select } from "redux-saga/effects";
import { AppAction } from "../action_template";
import { EntitiesResponse } from "../api_endpoints/api_response_types";
import EntitiesApi from "../api_endpoints/entities_api";
import { PromiseStatus, sleep, to } from "../helpers";
import { RootState } from "../redux";
import { UserSlice } from "../user/user_slice";
import { GET_ENTITY_REQUEST } from "./entity_get_action";

type deleteEntityRequestPayload = EntitiesResponse & { closeFn: () => void };

export class DELETE_ENTITY_REQUEST extends AppAction<deleteEntityRequestPayload> {
    type = "DELETE_ENTITY_REQUEST";

    constructor(payload: deleteEntityRequestPayload) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.entity_slice.delete_entity_promise = PromiseStatus.pending;
        });
    }

    static *delete_entity_saga(action: DELETE_ENTITY_REQUEST): Generator<any, any, any> {
        const user_slice: UserSlice = yield select((state: RootState) => state.user_slice);

        const [_, error] = yield call(() =>
            to(
                EntitiesApi.delete_entity({
                    tkn: user_slice.auth_id!,
                    user: user_slice.user!,
                    entity: action.payload!
                })
            )
        );
        yield put(new DELETE_ENTITY_FINISH());
        yield delay(1); // Trick to force a rerender

        if (error) {
            yield put(new DELETE_ENTITY_ERROR());
            return;
        }

        action?.payload!.closeFn?.();
        yield put(new DELETE_ENTITY_SUCCESS(action.payload!));
        yield put(new GET_ENTITY_REQUEST());
        return;
    }
}

class DELETE_ENTITY_ERROR extends AppAction<void> {
    type = "DELETE_ENTITY_ERROR";

    reduce(state: RootState): RootState {
        return state;
    }
}

class DELETE_ENTITY_SUCCESS extends AppAction<deleteEntityRequestPayload> {
    type = "DELETE_ENTITY_SUCCESS";

    constructor(payload: deleteEntityRequestPayload) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return state;
        // const project = state.entity_slice.projects[this.payload!.project_id!];
        // const { [this.payload!.entity_id!]: _deleted_item, ...entities } = project.entities;

        // return produce(state, (draft) => {
        //     draft.entity_slice.projects[this.payload!.project_id!].entities = entities;
        //     draft.entity_slice.delete_entity_promise = PromiseStatus.success;
        // });
    }
}

/**
 * Called after a request has executed to reestablish the idle state
 */
class DELETE_ENTITY_FINISH extends AppAction<void> {
    type = "DELETE_ENTITY_FINISH";

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.entity_slice.delete_entity_promise = PromiseStatus.idle;
        });
    }
}
