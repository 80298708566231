import type { AnyAction } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { AppAction, SerializeAppActionsMiddleware } from "./action_template";
import { UserSlice } from "./user/user_slice";
import { ManagementSlice } from "./management_slice/management_slice";
import { EntitySlice } from "./entity_slice/entity_slice";

import { rootSaga } from "./sagas";
import { PromiseStatus } from "./helpers";

type rootReducer = {
    user_slice: UserSlice;
    management_slice: ManagementSlice;
    entity_slice: EntitySlice;
};

const persistConfig: PersistConfig<any> = {
    key: "root",
    storage
};

/**
 * Persist the redux state in local storage
 */
const persistedReducer = persistReducer(persistConfig, (state, action: AnyAction) => {
    /**
     * Execute this
     */
    if (action.reduce) return action.reduce(state);

    return state;
});

/**
 * Saga middleware to execute async actions with redux
 */
const sagaMiddleware = createSagaMiddleware();

export const default_state: rootReducer = {
    entity_slice: {
        projects: [],
        entities_types: {},
        update_entity_promise: PromiseStatus.idle,
        delete_entity_promise: PromiseStatus.idle,
        get_entity_promise: PromiseStatus.idle
    },
    management_slice: {
        users: [],
        user_status: [],
        roles: [],
        get_user_request_state: PromiseStatus.idle,
        delete_user_request_state: PromiseStatus.idle,
        update_user_request_state: PromiseStatus.idle
    },
    user_slice: {
        auth_id: null,
        user: null,
        auth_request_status: PromiseStatus.idle
    }
};

const store = configureStore<rootReducer>({
    preloadedState: default_state,
    reducer: persistedReducer as any,
    middleware: (middleware) =>
        [
            SerializeAppActionsMiddleware,
            sagaMiddleware,
            ...middleware({ serializableCheck: false })
        ] as any
});

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);

export default store;

export class RESET_STATE extends AppAction<void> {
    type = "RESET_STATE";

    reduce(state: RootState): RootState {
        return default_state;
    }
}
