import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GET_ENTITY_REQUEST } from "../redux/entity_slice/entity_get_action";
import { useAppDispatch, useAppSelector } from "../redux/redux";
import { useRole } from "../redux/user/user_slice";

const Home = () => {
    const user = useAppSelector((state) => state.user_slice.user);
    const { isAdmin, isSuperAdmin } = useRole();
    const navigate = useNavigate();

    return (
        <div>
            <div>
                <h2>Bienvenido, {user?.nombre}</h2>
                <h6>{user?.correo}</h6>
                {isAdmin && <h6>Membresía valida hasta el {user?.fecha_exp_lic.toString()}</h6>}
                {isAdmin && (
                    <Button onClick={() => navigate("/entities")}>Manejar mis entidades</Button>
                )}
                {isSuperAdmin && (
                    <Button onClick={() => navigate("/users")}>Manejar Usuarios</Button>
                )}
            </div>
        </div>
    );
};

export default Home;
