import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Form, FormGroup, InputGroup, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import UserAvatar from "../components/UserAvatar";
import { PromiseStatus } from "../redux/helpers";
import { UPDATE_USER_REQUEST } from "../redux/management_slice/user_update_action";
import { useAppDispatch, useAppSelector } from "../redux/redux";
import { useRole } from "../redux/user/user_slice";

export type EditUserPayload = {
    name: string;
    surname: string;
    username: string;
    email: string;
    password: string;
    role: string;
    membership_expiration: Date;
};

const EditUser: React.FC<{ isNew?: boolean }> = ({ isNew = false }) => {
    const { user_id } = useParams<{ user_id?: string }>();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const navigate = useNavigate();

    const updatePromiseState = useAppSelector(
        (state) => state.management_slice.update_user_request_state
    );

    const dispatch = useAppDispatch();
    const currentUser = useAppSelector((state) => state.user_slice.user);
    const user = useAppSelector((state) =>
        user_id ? state.management_slice.users[Number.parseInt(user_id)] : null
    );

    const projects = useAppSelector((state) => state.entity_slice.projects);
    const roles = useAppSelector((state) =>
        currentUser?.role == "admin"
            ? state.management_slice.roles.filter((i) => i.nu_tipo_entidad_prf === "3")
            : state.management_slice.roles
    );

    const [role, setRole] = useState<string>();

    const status = useAppSelector((state) => state.management_slice.user_status);

    const _onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const formDataObj = Object.fromEntries(formData.entries()) as any;

        dispatch(
            new UPDATE_USER_REQUEST({
                old_user: user,
                status: formDataObj.status && status[formDataObj.status],
                //
                nombreEmpresa: formDataObj.nombreEmpresa,
                nombre: formDataObj.nombrecompleto,
                email: formDataObj.email,
                proyecto: formDataObj.project_index
                    ? projects[formDataObj.project_index]
                    : projects[0],
                username: formDataObj.username,
                contraseña: formDataObj.contraseña,
                rol: roles[formDataObj.rol_index],
                fecha: formDataObj.fecha,
                onFinish: () => navigate("/users")
            })
        );
    };

    const rol = useRole();

    const colGutter = { marginTop: "1rem" };

    return (
        <>
            <h2>&lsaquo; Usuario </h2>
            <div>
                <Form onSubmit={_onSubmit} style={{ maxWidth: "680px", margin: "0 auto" }}>
                    <h2>{isNew ? "Crear nuevo usuario" : "Editar usuario"}</h2>
                    <div style={{ justifyContent: "center", display: "flex" }}>
                        <UserAvatar size="200px" scale={10} />
                    </div>
                    <Col style={{ marginTop: "2rem" }}>
                        <Form.Group>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                required
                                name="nombrecompleto"
                                placeholder="Nombre"
                                defaultValue={user?.nombrecompleto}
                            />
                        </Form.Group>
                    </Col>
                    <Row>
                        <Form.Group style={colGutter}>
                            <Form.Label>Correo electrónico</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                name="email"
                                placeholder="Email"
                                defaultValue={user?.emailuser}
                            />
                        </Form.Group>

                        <FormGroup style={colGutter}>
                            <Form.Label>Contraseña</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    required={!user}
                                    placeholder="Contraseña"
                                    name="contraseña"
                                    type={isPasswordVisible ? "text" : "password"}
                                />
                                <Button
                                    variant="outline-secondary"
                                    id="button-addon2"
                                    style={{ width: "50px" }}
                                    onClick={() => setIsPasswordVisible((b) => !b)}>
                                    <FontAwesomeIcon
                                        icon={isPasswordVisible ? faEye : faEyeSlash}
                                    />
                                </Button>
                            </InputGroup>
                        </FormGroup>
                        <Form.Group>
                            <Form.Label>Rol:</Form.Label>
                            <Form.Select
                                placeholder="Rol"
                                name="rol_index"
                                onChange={(e) => {
                                    setRole(e.currentTarget.value);
                                }}>
                                {roles.map((rol, index) => {
                                    return <option value={index}>{rol.descripcion}</option>;
                                })}
                            </Form.Select>
                        </Form.Group>
                        {!rol.isAdmin && (
                            <Form.Group style={colGutter}>
                                <Form.Label>Nombre de la empresa</Form.Label>
                                <Form.Control
                                    required
                                    type="empresa"
                                    name="nombreEmpresa"
                                    placeholder="empresa"
                                    defaultValue={user?.empresa}
                                />
                            </Form.Group>
                        )}
                        {user_id && (
                            <>
                                <Form.Group style={colGutter}>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select placeholder="Select" name="status">
                                        {status.map((_status, index) => (
                                            <option value={index}>{_status.descripcion}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </>
                        )}
                        {role !== "2" && !rol.isAdmin && (
                            <Form.Group style={colGutter}>
                                <Form.Label>Membresía valida por:</Form.Label>
                                <Form.Select name="fecha" placeholder="Select">
                                    <option value={"+1 day"}>1 días</option>
                                    <option value={"+15 day"}>15 días</option>
                                    <option value={"+1 month"}>1 mes</option>
                                    <option value={"+3 month"}>3 meses</option>
                                    <option value={"+6 month"}>6 meses</option>
                                    <option value={"+1 year"}>1 año</option>
                                </Form.Select>
                            </Form.Group>
                        )}
                        {Number.parseInt(role ?? "0") === 2 && (
                            <Form.Group style={colGutter}>
                                <Form.Label>Proyecto a asignar:</Form.Label>
                                <Form.Select placeholder="Select" name="project_index">
                                    {projects.map((proy, index) => {
                                        return <option value={index}>{proy.descripcion}</option>;
                                    })}
                                </Form.Select>
                            </Form.Group>
                        )}
                        <Button
                            type="submit"
                            style={{ marginTop: "2rem" }}
                            disabled={updatePromiseState === PromiseStatus.pending}>
                            {updatePromiseState !== PromiseStatus.pending ? (
                                "Guardar"
                            ) : (
                                <Spinner animation="border" role="status" size="sm">
                                    <span className="visually-hidden">Cargando...</span>
                                </Spinner>
                            )}
                        </Button>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default EditUser;
