import { ReactNode, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { PromiseStatus } from "../../redux/helpers";

export type DeleteModalProps = {
    onClose: () => void;
    onDelete: () => void;
    promiseStatus: PromiseStatus;
    children?: ReactNode;
    title: ReactNode;
};

const DeleteModal: React.FC<DeleteModalProps> = ({
    onClose,
    onDelete,
    title,
    promiseStatus,
    children
}) => {
    useEffect(() => {
        if (promiseStatus === PromiseStatus.success) onClose();
    }, [promiseStatus]);

    const isPending = promiseStatus === PromiseStatus.pending;

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cerrar
                </Button>
                <Button variant="danger" onClick={onDelete} disabled={isPending}>
                    Eliminar&nbsp;
                    {isPending && <Spinner animation="border" role="status" size="sm" />}
                </Button>
            </Modal.Footer>
        </>
    );
};

export default DeleteModal;
