import styled from "styled-components";
import { Navbar } from "react-bootstrap";
import { colors } from "../../styles/variables";

export const AppNavbar = styled(Navbar)`
    background: ${colors.primary};
    padding: 0.8rem 2rem 0.8rem 1.1rem;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const AppNavbarBrand = styled(Navbar.Brand)`
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
`;

export const AppNavbarToggler = styled(Navbar.Toggle)`
    border: none;
    outline: none;

    &:focus {
        box-shadow: none;
    }
`;
