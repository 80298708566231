import { useState } from "react";
import { Button } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import LogoutOverlay from "./Logout";
import MainSidebar from "./Sidebar";
import { AppNavbar, AppNavbarBrand } from "./styles";

const ButtonMenu = styled(Button)`
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: none;
    padding: 0px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    &:hover {
        background-color: transparent;
        .menu__button-bar {
            opacity: 0.9;
            transition: 0.2s;
        }
    }
`;

const MenuBar = styled.div`
    content: "";
    width: 100%;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    transition: 0.2s;
    opacity: 0.7;
`;

const Layout = () => {
    const navigator = useNavigate();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    return (
        <>
            <div className="h-100">
                <AppNavbar expand="lg" variant="dark">
                    <div className="d-flex align-items-center">
                        <ButtonMenu onClick={() => setIsSidebarOpen((isOpen) => !isOpen)}>
                            <MenuBar className="menu__button-bar" />
                            <MenuBar className="menu__button-bar" />
                            <MenuBar className="menu__button-bar" />
                        </ButtonMenu>
                        <AppNavbarBrand onClick={() => navigator("/")}>Panel administrativo</AppNavbarBrand>
                    </div>
                    <div className="d-flex align-items-center">
                        <LogoutOverlay />
                    </div>
                </AppNavbar>
                <div className="d-flex" style={{ height: "calc(100% - 80px)" }}>
                    <MainSidebar isSidebarOpen={isSidebarOpen} />
                    <div
                        className="content p-4"
                        style={{ width: "calc(100% - 64px)", overflow: "scroll" }}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;
