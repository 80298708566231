import { to } from "../helpers";
import { User } from "../user/user_slice";
import { ProjectResponse, UserRespose } from "./api_response_types";

export type CreateUserArgs = {
    nombreEmpresa: string;
    nombre: string;
    proyecto: ProjectResponse;
    email: string;
    username: string;
    contraseña: string;
    rol: any;
    fecha: string;
    //
    status: any;
    old_user?: UserRespose | null;
    onFinish?: () => void;
};

class UsersApi {
    static async get_roles(tkn: string) {
        const params = new URLSearchParams();
        params.append("tkn", tkn);
        params.append("consulta", "Roles");

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/usuarios.php?${params.toString()}`, {
                method: "get"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const users = await response?.json();

        return users;
    }

    static async get_users_status(tkn: string) {
        const params = new URLSearchParams();
        params.append("tkn", tkn);
        params.append("consulta", "Status de Usuario");

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/usuarios.php?${params.toString()}`, {
                method: "get"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const userStates = await response?.json();

        return userStates;
    }

    static async get_useradmin_user(tkn: string, user: User) {
        const params = new URLSearchParams();
        params.append("tkn", tkn);
        // params.append("consulta", "usuarios de superadmin");
        params.append("dni_enterprise", user.dni_enterprise);
        params.append("nu_tipo_entidad_doc_ent", user.nu_tipo_entidad_doc_ent);
        params.append("in_clasificacion_tipo_ent_doc_ent", user.in_clasificacion_tipo_ent_doc_ent);
        params.append("buscar", "");

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/usuarios.php?${params.toString()}`, {
                method: "get"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const users = await response?.json();

        return users;
    }

    static async get_superadmin_user(tkn: string) {
        const params = new URLSearchParams();
        params.append("tkn", tkn);
        params.append("consulta", "usuarios de superadmin");

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/usuarios.php?${params.toString()}`, {
                method: "get"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const users = await response?.json();

        return users;
    }

    static async create_user(tkn: string, user: User, values: CreateUserArgs) {
        const params = new URLSearchParams();
        // const params = new FormData();
        params.append("tkn", tkn);
        params.append("consulta", "Insertar");

        // User data
        params.append("nombre", values.nombre);
        params.append("email", values.email);
        params.append("username", "values.email");
        // params.append("nombreEmpresa", values.nombreEmpresa);
        params.append("nu_tipo_entidad_pry", values.proyecto.nu_tipo_entidad_pry);
        params.append(
            "in_clasificacion_tipo_ent_pry",
            values.proyecto.in_clasificacion_tipo_ent_pry
        );
        // Rol
        params.append("nu_tipo_entidad_prf", values.rol.nu_tipo_entidad_prf);
        params.append("in_clasificacion_tipo_ent_prf", values.rol.in_clasificacion_tipo_ent_prf);

        params.append("nu_tipo_entidad_doc_ent", user.nu_tipo_entidad_doc_ent);

        params.append("dni_enterprise", user.dni_enterprise);
        // params.append("apellido", "a");
        params.append("password", values.contraseña);
        params.append("fecha", values.fecha);
        //
        params.append("in_clasificacion_tipo_ent_doc_ent", user.in_clasificacion_tipo_ent_doc_ent);
        // params.append("in_clasificacion_tipo_ent_do", user.in_clasificacion_tipo_ent_doc_ent)

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/usuarios.php?${params.toString()}`, {
                method: "post"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const status = await response?.json();

        return status;
    }

    static async edit_user(tkn: string, user: User, values: CreateUserArgs) {
        const params = new URLSearchParams();
        // const params = new FormData();
        params.append("tkn", tkn);
        // User data
        params.append("email", values.email);
        //
        params.append("nuevoNombre", values.nombre);
        params.append("nuevoApellido", values.nombre);
        params.append("nuevoUserName", values.nombre);
        params.append("nuevoPassword", values.contraseña);
        params.append("nuevoEmail", values.email);
        params.append("dni", values.old_user?.dni!);
        params.append("nu_tipo_entidad_doc", values.old_user?.nu_tipo_entidad_doc!);
        params.append(
            "in_clasificacion_tipo_ent_doc",
            values.old_user!.in_clasificacion_tipo_ent_doc
        );
        params.append("fecha", values.fecha);
        //
        params.append("nu_tipo_entidad_sta", values.status.nu_tipo_entidad_sta);
        params.append("in_clasificacion_tipo_ent_sta", values.status.in_clasificacion_tipo_ent_sta);

        params.append("nu_tipo_entidad_prf", values.rol.nu_tipo_entidad_prf);
        params.append("in_clasificacion_tipo_ent_prf", values.rol.in_clasificacion_tipo_ent_prf);

        params.append("dni_enterprise", user.dni_enterprise);
        params.append("in_clasificacion_tipo_ent_doc_ent", user.in_clasificacion_tipo_ent_doc_ent);
        params.append("nu_tipo_entidad_doc_ent", user.nu_tipo_entidad_doc_ent);
        //

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/usuarios.php?${params.toString()}`, {
                method: "put"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const status = await response?.json();

        return status;
    }

    static async delete_user(tkn: string, user: User, user_to_delete: UserRespose) {
        const params = new URLSearchParams();
        params.append("tkn", tkn);
        //
        params.append("email", user_to_delete.emailuser);
        params.append("dni", user_to_delete.dni);
        params.append("nu_tipo_entidad_doc", user_to_delete.nu_tipo_entidad_doc);

        params.append("in_clasificacion_tipo_ent_doc", user.in_clasificacion_tipo_ent_doc_ent);
        params.append("dni_enterprise", user.dni_enterprise);
        params.append("nu_tipo_entidad_doc_ent", user.nu_tipo_entidad_doc_ent);
        params.append("in_clasificacion_tipo_ent_doc_ent", user.in_clasificacion_tipo_ent_doc_ent);

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/usuarios.php?${params.toString()}`, {
                method: "delete"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const status = await response?.json();

        return status;
    }
}

export default UsersApi;
