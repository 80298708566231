import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { RootState } from "./redux";

/**
 * Main action class for the redux actions.
 *
 * Normally redux actions are plain objects but making
 * it objects open a lot of possibilities and patterns.
 * For example a custom class for enhanced actions that
 * takes data for the store.
 */
export abstract class AppAction<Payload> implements AnyAction {
    type!: string;
    payload: Payload | undefined;

    /**
     * This is the function called by the main reducer. This function
     * call the state and mutate its value. Should be pure and only depend
     * on the previous state and the payload.
     * @param state RootState
     */
    abstract reduce(state: RootState): RootState;

    /**
     * Redux only support plain javascript objects, soo this function takes
     * the this custom action and make it a "plain" object.
     * @returns
     */
    serialize() {
        return {
            type: this.type,
            reduce: this.reduce,
            payload: this.payload
        };
    }
}

/**
 * Middleware function to serialize AppAction to object like redux actions
 */
export const SerializeAppActionsMiddleware: Middleware =
    (api: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
        if (action instanceof AppAction) {
            return next(action.serialize());
        }

        return next(action);
    };
