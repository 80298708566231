import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { StyledComponent } from "styled-components";

const _GreyCircleDiv: StyledComponent<any, any> = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props: any) => props.size};
    height: ${(props: any) => props.size};
    background-color: grey;
    margin-left: 1rem;
    border-radius: 50%;
    overflow: hidden;
`;

const UserAvatar: React.FC<{ size: string; scale: number }> = ({ size, scale }) => (
    <_GreyCircleDiv size={size}>
        <FontAwesomeIcon icon={faUser} style={{ transform: `scale(${scale}) translateY(3px)` }} />
    </_GreyCircleDiv>
);

export default UserAvatar;
