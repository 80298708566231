import produce from "immer";
import { call, put, select } from "redux-saga/effects";
import { AppAction } from "../action_template";
import { PromiseStatus, sleep, to } from "../helpers";
import { RootState } from "../redux";
import { UserSlice } from "../user/user_slice";
import { toast } from "react-toastify";
import UsersApi from "../api_endpoints/user_api";

export class GET_USER_REQUEST extends AppAction<void> {
    type = "GET_USER_REQUEST";

    constructor(payload: void) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.management_slice.get_user_request_state = PromiseStatus.pending;
        });
    }

    static *get_users_saga(action: GET_USER_REQUEST): Generator<any, any, any> {
        const _toastId = toast("Actualizando usuarios", { isLoading: true });

        const user_slice: UserSlice = yield select((state: RootState) => state.user_slice);

        const [_result, error] = yield call(() =>
            to(
                Promise.all([
                    user_slice.user?.role === "admin"
                        ? UsersApi.get_useradmin_user(user_slice.auth_id!, user_slice.user!)
                        : UsersApi.get_superadmin_user(user_slice.auth_id!),
                    UsersApi.get_roles(user_slice.auth_id!),
                    UsersApi.get_users_status(user_slice.auth_id!)
                ])
            )
        );
        yield put(new GET_USER_FINISH());

        if (error) {
            toast.update(_toastId, {
                isLoading: false,
                type: toast.TYPE.ERROR,
                render: "Hubo un error actualizando los usuarios"
            });
            console.error(error);

            yield put(new GET_USER_ERROR());
            return;
        }

        const users = _result[0];

        toast.update(_toastId, {
            isLoading: false,
            type: toast.TYPE.SUCCESS,
            render: "Usuarios actualizados",
            autoClose: 300
        });
        yield put(new GET_USER_SUCCESS(_result));
        return;
    }
}

class GET_USER_ERROR extends AppAction<void> {
    type = "GET_USER_ERROR";

    reduce(state: RootState): RootState {
        return state;
    }
}

/**
 * Action called to set the entities in the state
 */
class GET_USER_SUCCESS extends AppAction<any> {
    type = "GET_USER_SUCCESS";

    constructor(payload: any) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.management_slice.users = this.payload[0]!;
            draft.management_slice.roles = this.payload[1]!;
            draft.management_slice.user_status = this.payload[2];
            // draft.entity_slice.entities_types = this.payload!.entity_types!.reduce(
            //     (group, curr) => {
            //         group[curr.nu_entidad] = curr;
            //         return group;
            //     },
            //     <Record<string, EntityTypeResponse>>{}
            // );
        });
    }
}

/**
 * Called after a request has executed to reestablish the idle state
 */
class GET_USER_FINISH extends AppAction<void> {
    type = "GET_USER_FINISH";

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.management_slice.get_user_request_state = PromiseStatus.idle;
        });
    }
}
