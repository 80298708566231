const colors = {
    primary: "#00AAF8",
    secondary: "#00193E",
    success: "#2D936C",
    warning: "#F5E663",
    error: "#9C3848",
    gray: "#999999",
    white: "#F8F8FC",
    black: "#000000",
    disabled: "#CCCCCC",
    hover: {
        primary: "#0095d9",
        secondary: "#001330"
    },
    active: {
        primary: "#0088cc"
    }
};

const sizes = {
    navbarHeight: "80px",
    cardWidth: "560px"
};

export { colors, sizes };
