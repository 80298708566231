import produce from "immer";
import { call, put, delay, select } from "redux-saga/effects";
import { AppAction } from "../action_template";
import { UserRespose } from "../api_endpoints/api_response_types";
import UsersApi from "../api_endpoints/user_api";
import { GET_ENTITY_REQUEST } from "../entity_slice/entity_get_action";
import { PromiseStatus, sleep, to } from "../helpers";
import { RootState } from "../redux";
import { UserSlice } from "../user/user_slice";
import { GET_USER_REQUEST } from "./user_get_action";

type DeleteUserPayload = UserRespose & {closeFn: () => void}

export class DELETE_USER_REQUEST extends AppAction<DeleteUserPayload> {
    type = "DELETE_USER_REQUEST";

    constructor(payload: DeleteUserPayload) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.management_slice.delete_user_request_state = PromiseStatus.pending;
        });
    }

    static *delete_user_saga(action: DELETE_USER_REQUEST): Generator<any, any, any> {
        const user_slice: UserSlice = yield select((state: RootState) => state.user_slice);

        const [_, error] = yield call(() =>
            to(UsersApi.delete_user(user_slice.auth_id!, user_slice.user!, action.payload!))
        );
        
        yield put(new DELETE_USER_FINISH());

        if (error) {
            yield put(new DELETE_USER_ERROR());
            return;
        }

        action.payload?.closeFn()
        yield put(new DELETE_USER_SUCCESS(action.payload!));
        yield put(new GET_USER_REQUEST());
        return;
    }
}

class DELETE_USER_ERROR extends AppAction<void> {
    type = "DELETE_USER_ERROR";

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.management_slice.delete_user_request_state = PromiseStatus.error;
        });
    }
}

class DELETE_USER_SUCCESS extends AppAction<UserRespose> {
    type = "DELETE_USER_SUCCESS";

    constructor(payload: UserRespose) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return state;
    }
}

/**
 * Called after a request has been executed to reestablish the idle state
 */
class DELETE_USER_FINISH extends AppAction<void> {
    type = "DELETE_USER_FINISH";

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.management_slice.delete_user_request_state = PromiseStatus.idle;
        });
    }
}
