import { takeLeading } from "redux-saga/effects";
import { DELETE_ENTITY_REQUEST } from "./entity_slice/entity_delete_action";
import { GET_ENTITY_REQUEST } from "./entity_slice/entity_get_action";
import { UPDATE_ENTITY_REQUEST } from "./entity_slice/entity_update_action";
import { DELETE_USER_REQUEST } from "./management_slice/user_delete_action";
import { GET_USER_REQUEST } from "./management_slice/user_get_action";
import { UPDATE_USER_REQUEST } from "./management_slice/user_update_action";
import { LOGIN_REQUEST } from "./user/user_login_action";

/**
 * Main saga for the app all other sagas should spawn here.
 */
export function* rootSaga() {
    // LOGIN
    yield takeLeading("LOGIN_REQUEST", LOGIN_REQUEST.login_saga);
    // ENTITIES
    yield takeLeading("GET_ENTITY_REQUEST", GET_ENTITY_REQUEST.get_entity_saga);
    yield takeLeading("DELETE_ENTITY_REQUEST", DELETE_ENTITY_REQUEST.delete_entity_saga);
    yield takeLeading("UPDATE_ENTITY_REQUEST", UPDATE_ENTITY_REQUEST.update_entity_saga);
    // USERS
    yield takeLeading("GET_USER_REQUEST", GET_USER_REQUEST.get_users_saga)
    yield takeLeading("DELETE_USER_REQUEST", DELETE_USER_REQUEST.delete_user_saga);
    yield takeLeading("UPDATE_USER_REQUEST", UPDATE_USER_REQUEST.update_user_saga);
}
