import { faEdit, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fuzzysort from "fuzzysort";
import { FormEventHandler, useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DeleteModal, { DeleteModalProps } from "../components/modals/CloseModal";
import { ModalRequest } from "../components/modals/modal_types";
import CustomTable from "../components/Table";
import { UserRespose } from "../redux/api_endpoints/api_response_types";
import { DELETE_USER_REQUEST } from "../redux/management_slice/user_delete_action";
import { GET_USER_REQUEST } from "../redux/management_slice/user_get_action";
import { useAppDispatch, useAppSelector } from "../redux/redux";

const Users = () => {
    const users = useAppSelector((state) => state.management_slice.users);
    const currentUser = useAppSelector((state) => state.user_slice.user);
    const usersArray = Object.values(users);
    const dispatch = useAppDispatch();
    const deleteUserPromise = useAppSelector(
        (state) => state.management_slice.delete_user_request_state
    );

    const navigate = useNavigate();
    const [modal, setModal] = useState<ModalRequest<UserRespose> | null>(null);
    const onModalClose = () => setModal(null);

    useEffect(() => {
        dispatch(new GET_USER_REQUEST());
    }, [dispatch]);

    // Search functionality

    const [searchResults, setSearchResults] = useState<UserRespose[] | null>(null);
    const onSearch: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const values = Object.fromEntries<any>(formData.entries());

        const value = values.search ?? "";
        if (value === "") return setSearchResults(null);

        const results = fuzzysort.go(value, usersArray, { key: "name" });
        setSearchResults(results.map((i) => i.obj));
    };

    return (
        <div>
            <h2>Usuarios</h2>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
                <span>
                    <Form onSubmit={onSearch}>
                        <InputGroup>
                            <Form.Control name="search" placeholder="Buscar por nombre" />
                            <Button variant="outline-secondary" id="button-addon2" type="submit">
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </InputGroup>
                    </Form>
                </span>
                <Button onClick={() => navigate("/users/new")}>Crear nuevo usuario</Button>
            </div>
            <CustomTable
                elementsPerPage={9}
                data={searchResults ?? usersArray}
                head={currentUser?.role === "admin" ? UserAdminTableHeader : superUsersTableHeader}
                renderItem={(user, index, itemIndex) => (
                    <RenderUserTableRow
                        navigate={navigate}
                        row={user}
                        setModal={setModal}
                        index={itemIndex}
                    />
                )}
            />
            <Modal show={modal !== null} onHide={onModalClose} centered>
                <DeleteModal
                    onClose={onModalClose}
                    promiseStatus={deleteUserPromise}
                    title={<>¿Eliminar {modal?.value?.nombrecompleto}?</>}
                    onDelete={() => {
                        modal?.value &&
                            dispatch(
                                new DELETE_USER_REQUEST({
                                    ...(modal?.value ?? {}),
                                    closeFn: () => setModal(null)
                                })
                            );
                    }}>
                    ¿Esta seguro de que desea eliminar al usuario &nbsp;
                    <strong>{modal?.value?.nombrecompleto}?</strong>&nbsp; Una vez borrado no podrá
                    recuperarse.
                </DeleteModal>
            </Modal>
        </div>
    );
};

const UserAdminTableHeader = (
    <tr>
        <th>Nombre Completo</th>
        <th>Email</th>
        <th>Rol</th>
        <th>Acciones</th>
    </tr>
);

const superUsersTableHeader = (
    <tr>
        <th>Nombre Completo</th>
        <th>Email</th>
        <th>Status</th>
        <th>Empresa</th>
        <th>Rol</th>
        <th>Acciones</th>
    </tr>
);

const RenderUserTableRow: React.FC<{
    navigate: any;
    setModal: any;
    row: UserRespose;
    index: number;
}> = ({ navigate, row, setModal, index }) => {
    const _anyrow: any = row;

    return (
        <tr key={`row-${index}`}>
            <td>{row.nombrecompleto ?? _anyrow.Nombre}</td>
            <td>{row.emailuser ?? _anyrow.email}</td>
            {row.estatus && (
                <>
                    <td>{row.estatus}</td>
                    <td>{row.empresa}</td>
                </>
            )}
            <td>{row.rol ?? _anyrow.perfil}</td>
            <td>
                <Button variant="link" onClick={() => navigate(`/users/edit/${index}`)}>
                    <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button variant="link" onClick={() => setModal({ value: row, action: "delete" })}>
                    <FontAwesomeIcon color="red" icon={faTrash} />
                </Button>
            </td>
        </tr>
    );
};

export default Users;
