import { FormEventHandler, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { EntitiesResponse } from "../../redux/api_endpoints/api_response_types";

import { UPDATE_ENTITY_REQUEST } from "../../redux/entity_slice/entity_update_action";
import { PromiseStatus } from "../../redux/helpers";
import { useAppDispatch, useAppSelector } from "../../redux/redux";

const EditEntityModal: React.FC<{
    close: () => void;
    entity?: EntitiesResponse;
    projectIndex: number;
    isNew?: boolean;
}> = ({ close, entity, isNew = false, projectIndex }) => {
    const dispatch = useAppDispatch();
    const projects = useAppSelector((state) => state.entity_slice.projects);
    const entity_types = useAppSelector((state) => state.entity_slice.entities_types);
    const update_request_status = useAppSelector(
        (state) => state.entity_slice.update_entity_promise
    );

    useEffect(() => {
        if (update_request_status === PromiseStatus.success) close();
    }, [update_request_status]);

    const isPending = update_request_status === PromiseStatus.pending;

    const _onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const values = Object.fromEntries(formData.entries()) as any;

        dispatch(
            new UPDATE_ENTITY_REQUEST({
                descripcion: values.descripcion,
                project: projects[values.project],
                entity_type: entity_types[values.entity_type],
                //
                old_entity: entity,
                close: close
            })
        );
    };

    return (
        <>
            <Form onSubmit={_onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isNew ? "Crear nueva entidad" : `Editar ${entity?.descripcion}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "1rem 3rem" }}>
                    <Form.Group>
                        <Form.Label>Proyecto</Form.Label>
                        <Form.Select name="project" required defaultValue={projectIndex}>
                            {projects.map((project, index) => (
                                <option key={`project-${project.descripcion}`} value={index}>
                                    {project.descripcion}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{ marginTop: "1rem" }}>
                        <Form.Label>Tipo de entidad</Form.Label>
                        <Form.Select name="entity_type" required defaultValue={entity?.nu_entidad}>
                            {Object.values(entity_types).map((type, index) => (
                                <option key={`entity-${type.nu_entidad}`} value={type.nu_entidad}>
                                    {type.descripcion}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{ marginTop: "1rem" }}>
                        <Form.Label>Nombre de la entidad</Form.Label>
                        <Form.Control
                            required
                            name="descripcion"
                            placeholder="Nombre"
                            defaultValue={entity?.descripcion}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={isPending}
                        style={{ padding: "8px 4rem" }}>
                        Guardar&nbsp;
                        {isPending && <Spinner animation="border" role="status" size="sm" />}
                    </Button>
                </Modal.Footer>
            </Form>
        </>
    );
};

export default EditEntityModal;
