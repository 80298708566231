import { faker } from "@faker-js/faker";
import produce from "immer";
import { toast } from "react-toastify";
import { call, put, delay, select } from "redux-saga/effects";
import { AppAction } from "../action_template";
import UsersApi, { CreateUserArgs } from "../api_endpoints/user_api";
import { PromiseStatus, sleep, to } from "../helpers";
import { RootState } from "../redux";
import { UserSlice } from "../user/user_slice";
import { GET_USER_REQUEST } from "./user_get_action";

export class UPDATE_USER_REQUEST extends AppAction<CreateUserArgs> {
    type = "UPDATE_USER_REQUEST";

    constructor(payload: CreateUserArgs) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.management_slice.update_user_request_state = PromiseStatus.pending;
        });
    }

    static *update_user_saga(action: UPDATE_USER_REQUEST): Generator<any, any, any> {
        const user_slice: UserSlice = yield select((state: RootState) => state.user_slice);
        const _toastId = toast("Insertando usuario", { isLoading: true });

        const [result, error] = yield call(() =>
            to(
                action.payload?.old_user
                    ? UsersApi.edit_user(user_slice.auth_id!, user_slice.user!, action.payload!)
                    : UsersApi.create_user(user_slice.auth_id!, user_slice.user!, action.payload!)
            )
        );

        if (error || result.codigoResultado === 0) {
            toast.update(_toastId, {
                isLoading: false,
                type: toast.TYPE.ERROR,
                render: "Hubo un error insertando el usuario"
            });

            yield put(new UPDATE_USER_ERROR());
            yield delay(1);
            yield put(new UPDATE_USER_FINISH());
            return;
        }

        toast.update(_toastId, {
            isLoading: false,
            type: toast.TYPE.SUCCESS,
            render: "Usuario insertado con éxito"
        });
        action.payload?.onFinish?.();
        yield put(new UPDATE_USER_SUCCESS(result));
        yield delay(1);
        yield put(new UPDATE_USER_FINISH());
        yield put(new GET_USER_REQUEST());
    }
}

class UPDATE_USER_ERROR extends AppAction<void> {
    type = "UPDATE_USER_ERROR";

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.management_slice.update_user_request_state = PromiseStatus.error;
        });
    }
}

class UPDATE_USER_SUCCESS extends AppAction<CreateUserArgs> {
    type = "UPDATE_USER_SUCCESS";

    constructor(payload: CreateUserArgs) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        const payload = this.payload!;
        return produce(state, (draft) => {
            draft.management_slice.update_user_request_state = PromiseStatus.success;
        });
    }
}

class UPDATE_USER_FINISH extends AppAction<void> {
    type = "UPDATE_USER_FINISH";

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.management_slice.update_user_request_state = PromiseStatus.idle;
        });
    }
}
