import produce from "immer";
import { call, put, select } from "redux-saga/effects";
import { AppAction } from "../action_template";
import EntitiesApi from "../api_endpoints/entities_api";
import {
    EntitiesResponse,
    EntityTypeResponse,
    ProjectResponse
} from "../api_endpoints/api_response_types";
import { PromiseStatus, sleep, to } from "../helpers";
import { RootState } from "../redux";
import { User } from "../user/user_slice";
import { toast } from "react-toastify";

/**
 * Main function to get the entities API, it calls the projects and entities endpoints, after that
 * for every project call the filter_by_project API to get all the projects.
 *
 * @param tkn user tkn
 * @param user User data
 * @returns
 */
const get_entities = async (tkn: string, user: User) => {
    const [definitions_response, definitions_error] = await to(
        Promise.all([
            EntitiesApi.get_projects_api(tkn, user),
            EntitiesApi.get_entities_types_api(tkn),
        ])
    );

    if (definitions_error) {
        console.error("Error getting definitions", definitions_error);
        return Promise.reject(definitions_error);
    }

    const [projects, entity_types] = definitions_response!;

    const _entity_types_map = entity_types.reduce((group, curr) => {
        group[curr.nu_entidad] = curr;

        return group;
    }, <Record<string, EntityTypeResponse>>{});

    const project_entities_promise = projects!.map((project) =>
        EntitiesApi.get_entities_by_project_api(tkn, user, project)
    );

    const [project_entities, err_entities] = await to(Promise.all(project_entities_promise));

    if (err_entities) {
        console.error("Error getting entities", definitions_error);
        return Promise.reject(err_entities);
    }

    return {
        entity_types,
        entities: projects!.map((project, index) => ({
            ...project,
            entities: project_entities![index].map((entity) => {
                const type = _entity_types_map[entity.nu_entidad];

                return {
                    type_descripcion: type?.descripcion ?? "Sin tipo",
                    type_in_clasificacion_ent: type?.in_clasificacion_ent ?? "",
                    ...entity
                };
            })
        }))
    };
};

type _GetEntitiesReturn = Awaited<ReturnType<typeof get_entities>>;

export type ProjectsWithEntities = _GetEntitiesReturn["entities"];

export class GET_ENTITY_REQUEST extends AppAction<void> {
    type = "GET_ENTITY_REQUEST";

    constructor(payload: void) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.entity_slice.get_entity_promise = PromiseStatus.pending;
        });
    }

    static *get_entity_saga(action: GET_ENTITY_REQUEST): Generator<any, any, any> {
        const _toastId = toast("Actualizando proyectos", { isLoading: true });

        const user = yield select((state: RootState) => state.user_slice.user);
        const tkn = yield select((state: RootState) => state.user_slice.auth_id);

        const [_result, error] = yield call(() => to(get_entities(tkn, user)));
        yield put(new GET_ENTITY_FINISH());

        if (error) {
            toast.update(_toastId, {
                isLoading: false,
                type: toast.TYPE.ERROR,
                render: "Hubo un error actualizando los proyectos"
            });

            yield put(new GET_ENTITY_ERROR());
            return;
        }

        const result: _GetEntitiesReturn = _result;

        toast.update(_toastId, {
            isLoading: false,
            type: toast.TYPE.SUCCESS,
            render: "Proyectos actualizados",
            autoClose: 300
        });
        yield put(new GET_ENTITY_SUCCESS(result));
        return;
    }
}

class GET_ENTITY_ERROR extends AppAction<void> {
    type = "GET_ENTITY_ERROR";

    reduce(state: RootState): RootState {
        return state;
    }
}

/**
 * Action called to set the entities in the state
 */
class GET_ENTITY_SUCCESS extends AppAction<_GetEntitiesReturn> {
    type = "GET_ENTITY_SUCCESS";

    constructor(payload: _GetEntitiesReturn) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            console.log({ ent: this.payload?.entities });
            draft.entity_slice.projects = this.payload?.entities!;
            draft.entity_slice.entities_types = this.payload!.entity_types!.reduce(
                (group, curr) => {
                    group[curr.nu_entidad] = curr;
                    return group;
                },
                <Record<string, EntityTypeResponse>>{}
            );
        });
    }
}

/**
 * Called after a request has executed to reestablish the idle state
 */
class GET_ENTITY_FINISH extends AppAction<void> {
    type = "GET_ENTITY_FINISH";

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.entity_slice.get_entity_promise = PromiseStatus.idle;
        });
    }
}
