import { FormEventHandler, useState } from "react";
import { Button, Col, Form, InputGroup, Spinner } from "react-bootstrap";
import { Navigate } from "react-router";
import PasswordInput from "../components/Forms/PasswordInput";
import { CardContainer, Card, CardFormTitle, FormLink } from "../components/Forms/styles";
import LoginNavbar from "../components/Layout/Navbar";
import { PromiseStatus } from "../redux/helpers";
import { useAppDispatch, useAppSelector } from "../redux/redux";
import { LOGIN_REQUEST } from "../redux/user/user_login_action";

const Login = () => {
    const dispatch = useAppDispatch();
    const auth_request_status = useAppSelector((state) => state.user_slice.auth_request_status);
    const auth_id = useAppSelector((state) => state.user_slice.auth_id);
    const [validated, setValidated] = useState(false);

    if (auth_id) return <Navigate to="/" replace />;

    const isRequestPending = auth_request_status === PromiseStatus.pending;
    const [password, setPassword] = useState<string>();

    const _onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        const form = e.currentTarget;
        e.preventDefault();

        if (!form.checkValidity()) {
            setValidated(true);

            return;
        }

        const formData = new FormData(form);
        const values = Object.fromEntries<any>(formData.entries());

        dispatch(new LOGIN_REQUEST({ email: values.email, password: values.password }));
    };

    // console.log(inputRef?.validity);

    return (
        <>
            <LoginNavbar />
            <CardContainer>
                <Card>
                    <Form noValidate validated={validated} onSubmit={_onSubmit}>
                        <CardFormTitle>Inicio de sesión</CardFormTitle>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <InputGroup>
                                <Form.Control
                                    required
                                    type="email"
                                    name="email"
                                    placeholder="Correo"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Por favor, introduzca un email valido.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                        <PasswordInput
                            renderChild={(isPasswordVisible) => (
                                <Form.Control
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Contraseña"
                                    name="password"
                                    minLength={6}
                                    type={isPasswordVisible ? "text" : "password"}
                                />
                            )}
                            validation={
                                <Form.Control.Feedback type="invalid">
                                    {password === ""
                                        ? "Por favor, introduzca una contraseña"
                                        : "Por favor, introduzca una contraseña valida"}
                                </Form.Control.Feedback>
                            }
                        />
                        <div className="text-center">
                            <Col>
                                <FormLink to="/" align="center">
                                    ¿Olvidaste tu contraseña?
                                </FormLink>
                            </Col>
                            <Button
                                type="submit"
                                className="mt-2"
                                disabled={isRequestPending}
                                style={{ width: "180px" }}>
                                {isRequestPending ? (
                                    <Spinner animation="border" role="status" size="sm">
                                        <span className="visually-hidden">Cargando...</span>
                                    </Spinner>
                                ) : (
                                    "Iniciar sesión"
                                )}
                            </Button>
                        </div>
                    </Form>
                </Card>
            </CardContainer>
        </>
    );
};

export default Login;
