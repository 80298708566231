import React, { ReactNode, useMemo, useState } from "react";
import { Pagination, Table } from "react-bootstrap";

type TableProps<T> = {
    // th row for the table
    head: ReactNode;
    // Array of elements to show in the table.
    data: T[];
    // Function to show render the items in the table
    renderItem: (arg: T, rowIndex: number, itemIndex: number) => ReactNode;
    // Number of elements to show per page
    elementsPerPage: number;
    // Function called when pagination change
    onPageChange?: (index: number) => void;
    //
    currentPage?: number;
};

/**
 * Custom Table component to display the data
 * @param props
 * @returns
 */
const CustomTable = <T extends object>(props: TableProps<T>) => {
    const [currentPage, setCurrentPage] = useState(props.currentPage ?? 0);

    const dataGroups = useMemo(
        () => sliceIntoChunks(props.data, props.elementsPerPage),
        [props.data, props.elementsPerPage]
    );

    const currentGroup = dataGroups[currentPage];

    return (
        <>
            <Table striped bordered hover>
                <thead>{props.head}</thead>
                <tbody>
                    {(currentGroup || []).map((row, index) => {
                        const _elementIndex = index + currentPage * props.elementsPerPage;
                        return (
                            <React.Fragment
                                key={`page-${currentPage}-row-${index}-${_elementIndex}`}>
                                {props.renderItem(row, index, _elementIndex)}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
            {!currentGroup && <h3>No hay datos</h3>}
            <Pagination>
                {Array.from(Array(dataGroups.length), (_, index) => {
                    return (
                        <Pagination.Item
                            key={`pag-${index}`}
                            active={index === currentPage}
                            onClick={() => {
                                props.onPageChange?.(index);
                                return setCurrentPage(index);
                            }}>
                            {index + 1}
                        </Pagination.Item>
                    );
                })}
            </Pagination>
        </>
    );
};

function sliceIntoChunks<T>(arr: T[], chunkSize: number) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

export default CustomTable;
