import { PromiseStatus } from "../helpers";
import { useAppSelector } from "../redux";

export type User = {
    nombre: string;
    correo: string;
    role: "superAdmin" | "admin" | "user";
    nu_tipo_entidad_prf: string;
    fecha_exp_lic: string;
    dni_enterprise: string;
    nu_tipo_entidad_doc_ent: string;
    in_clasificacion_tipo_ent_doc_ent: string;
};

export type UserSlice = {
    auth_id: string | null;
    auth_request_status: PromiseStatus;
    user: User | null;
};

const useRole = () => {
    const role = useAppSelector((state) => state.user_slice.user?.role);

    return {
        isSuperAdmin: role === "superAdmin",
        isAdmin: role === "admin",
        isUser: role === "user"
    };
};

export { useRole };
