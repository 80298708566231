import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";

const PasswordInput: React.FC<{
    renderChild: (isPasswordVisible: boolean) => ReactNode;
    validation?: ReactNode;
}> = ({ renderChild, validation }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    return (
        <InputGroup className="mb-3">
            {renderChild(isPasswordVisible)}
            <Button
                variant="outline-secondary"
                style={{ width: "50px" }}
                onClick={() => setIsPasswordVisible((b) => !b)}>
                <FontAwesomeIcon icon={isPasswordVisible ? faEye : faEyeSlash} />
            </Button>
            {validation}
        </InputGroup>
    );
};

export default PasswordInput;
