import { Routes, Route, Navigate } from "react-router-dom";
import { Layout } from "./components";
import * as pages from "./pages";
import { useAppSelector } from "./redux/redux";

const App = () => {
    const user = useAppSelector((state) => state.user_slice.user);

    if (!user) {
        return (
            <Routes>
                <Route path="/login" element={<pages.Login />} />
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/login" element={<Navigate to="/" />} />
            <Route path="/" element={<Layout />}>
                <Route index element={<pages.Home />} />
                <Route path="/entities" element={<pages.Entities />} />
                <Route path="/users" element={<pages.Users />} />
                <Route path="/users/new" element={<pages.EditUser isNew />} />
                <Route path="/users/edit/:user_id" element={<pages.EditUser />} />{" "}
                <Route path="*" element={<div>404</div>} />
            </Route>
        </Routes>
    );
};

export default App;
//
