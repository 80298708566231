import React, { useCallback } from "react";
import { useEffect, useRef, useState } from "react";
import { Button, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap";
import CustomTable from "../components/Table";
import { useAppDispatch, useAppSelector } from "../redux/redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import fuzzysort from "fuzzysort";
import { DELETE_ENTITY_REQUEST } from "../redux/entity_slice/entity_delete_action";
import { ModalRequest } from "../components/modals/modal_types";
import DeleteModal from "../components/modals/CloseModal";
import { GET_ENTITY_REQUEST } from "../redux/entity_slice/entity_get_action";
import EditEntityModal from "../components/modals/EditEntityModal";
import { useSearchParams } from "react-router-dom";
import { EntitiesResponse } from "../redux/api_endpoints/api_response_types";
import { PromiseStatus } from "../redux/helpers";

const Entities = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const projects = useAppSelector((state) => state.entity_slice.projects ?? []);

    const dispatch = useAppDispatch();

    const [selectedProjectIndex, setSelectedProjectIndex] = useState(
        Number.parseInt(searchParams.get("project") ?? "0")
    );
    const entities = projects?.[selectedProjectIndex]?.entities ?? [];

    const [modal, setModal] = useState<ModalRequest<number> | null>(null);
    const deletePromiseState = useAppSelector((state) => state.entity_slice.delete_entity_promise);
    useEffect(() => {
        dispatch(new GET_ENTITY_REQUEST());
    }, []);

    useEffect(() => {
        if (deletePromiseState === PromiseStatus.success) {
            setModal(null);
        }
    }, [deletePromiseState]);

    // Search

    const searchRef = useRef<HTMLInputElement>();
    const [searchResults, setSearchResults] = useState<EntitiesResponse[] | null>(null);
    const onSearch = (e?: any) => {
        if (e) e.preventDefault();

        const value = searchRef.current?.value ?? "";
        if (value === "") return setSearchResults(null);

        setSearchParams({ project: selectedProjectIndex.toString(), search: value });
        const results = fuzzysort.go(value, entities, { key: "descripcion" });

        setSearchResults(results.map((i) => i.obj));
    };

    const onModalClose = () => {
        setModal(null);
        onSearch();
    };

    const _onProjectChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>): void => {
        if (searchRef.current) {
            searchRef.current.value = "";
        }
        setSearchResults(null);
        setSearchParams({ project: e.target.value.toString() });
        setSelectedProjectIndex(Number.parseInt(e.target.value));
    }, []);

    const _onDelete = () => {
        if (!modal?.action) return console.error(modal);

        const _entity = projects[selectedProjectIndex].entities[modal.value!];

        dispatch(new DELETE_ENTITY_REQUEST({ ..._entity, closeFn: () => setModal(null) }));
    };

    return (
        <div>
            <h2>Mis proyectos</h2>
            <TableControls
                onSearch={onSearch}
                searchRef={searchRef}
                onProjectChange={_onProjectChange}
                selectedProjectIndex={selectedProjectIndex}
                projects={projects}
                setModal={setModal}
            />
            <CustomTable
                elementsPerPage={10}
                data={searchResults ?? entities}
                head={EntityTableHeader}
                currentPage={Number.parseInt(searchParams?.get("page") ?? "0")}
                onPageChange={(index) =>
                    setSearchParams({
                        project: selectedProjectIndex.toString(),
                        page: index.toString(),
                        search: searchRef.current?.value ?? ""
                    })
                }
                renderItem={(i, index, elementIndex) => (
                    <EntityTableRow row={i} index={elementIndex} setModal={setModal} />
                )}
            />
            <Modal show={modal !== null} onHide={onModalClose} centered backdrop={"static"}>
                {modal?.action === "new" && (
                    <EditEntityModal
                        close={onModalClose}
                        projectIndex={selectedProjectIndex}
                        isNew
                    />
                )}
                {modal?.action === "edit" && (
                    <EditEntityModal
                        close={onModalClose}
                        entity={projects[selectedProjectIndex].entities[modal.value!]}
                        projectIndex={selectedProjectIndex}
                    />
                )}
                {modal?.action === "delete" && (
                    <DeleteModal
                        onClose={onModalClose}
                        title={
                            <>
                                Eliminar entidad{" "}
                                {projects[selectedProjectIndex].entities[modal.value!].descripcion}
                            </>
                        }
                        onDelete={_onDelete}
                        promiseStatus={deletePromiseState}>
                        <>
                            ¿Esta seguro de que desea eliminar la entidad &nbsp;
                            <strong>
                                {projects[selectedProjectIndex].entities[modal.value!].descripcion}?
                            </strong>
                            &nbsp; Una vez borrada no puede recuperarse.
                        </>
                    </DeleteModal>
                )}
            </Modal>
        </div>
    );
};

export default Entities;

const EntityTableHeader = (
    <tr>
        <th>Tipo de entidad</th>
        <th>Descripción</th>
        <th>Acciones</th>
    </tr>
);

const EntityTableRow: React.FC<{
    setModal: any;
    row: any;
    index: number;
}> = ({ setModal, row, index }) => {
    return (
        <tr key={`row_${index}`}>
            <td>{row?.type_descripcion}</td>
            <td
                style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "200px",
                    whiteSpace: "nowrap"
                }}>
                {row.descripcion}
            </td>
            <td>
                <Button variant="link" onClick={() => setModal({ value: index, action: "edit" })}>
                    <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button variant="link" onClick={() => setModal({ value: index, action: "delete" })}>
                    <FontAwesomeIcon color="red" icon={faTrash} />
                </Button>
            </td>
        </tr>
    );
};

const TableControls: React.FC<{
    onSearch: (e?: any) => void;
    searchRef: React.MutableRefObject<HTMLInputElement | undefined>;
    onProjectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    selectedProjectIndex: number;
    projects: any[];
    setModal: React.Dispatch<React.SetStateAction<ModalRequest<number> | null>>;
}> = ({
    onSearch,
    searchRef,
    selectedProjectIndex,
    projects,
    onProjectChange: _onProjectChange,
    setModal
}) => {
    return (
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>
            <Form onSubmit={onSearch}>
                <InputGroup>
                    <FloatingLabel controlId="floating_search_label" label="Buscar entidades">
                        <Form.Control ref={(e: any) => (searchRef.current = e)} />
                    </FloatingLabel>
                    <Button variant="outline-secondary" id="button-addon2" type="submit">
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </InputGroup>
            </Form>
            <FloatingLabel
                controlId="floatingSelect"
                label="Proyecto seleccionado"
                style={{ width: "25%" }}>
                <Form.Select onChange={_onProjectChange} value={selectedProjectIndex}>
                    {projects &&
                        projects.map((project, index) => (
                            <option key={`project-${project.descripcion}`} value={index}>
                                {project.descripcion}
                            </option>
                        ))}
                </Form.Select>
            </FloatingLabel>
            <Button onClick={() => setModal({ value: null, action: "new" })}>
                Crear nueva entidad
            </Button>
        </div>
    );
};
