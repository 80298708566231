/**
 * Method to facilitate error management and avoid try/catch nesting with multiple
 * promise.
 *
 * @param promise Promise to await
 * @returns Tuple with the async operation results
 */
export async function to<T>(promise: Promise<T>): Promise<[T | null, any]> {
    try {
        const result = await promise;
        return [result, null];
    } catch (error) {
        return [null, error];
    }
}

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export type WithId<T extends object> = T & { id: string };

export const get_random = <T>(array: T[]): T => array[Math.floor(Math.random() * array.length)];

export enum PromiseStatus {
    idle,
    pending,
    success,
    error
}
