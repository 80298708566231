import { Card as BootstrapCard } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, sizes } from "../../styles/variables";

export const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
    height: calc(100% - ${sizes.navbarHeight});
`;

export const Card = styled(BootstrapCard)`
    width: ${sizes.cardWidth};
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 2rem 3rem;
    background: ${colors.white};
`;

export const CardForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const CardFormTitle = styled(BootstrapCard.Title)`
    font-family: "Roboto", sans-serif;
    color: ${colors.secondary};
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0.875rem;
`;

export const FormInput = styled.input`
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid ${colors.black};
    padding: 1.25rem 1.125rem;
    font-size: 1.2rem;
    margin-bottom: 0.875rem;

    &::placeholder {
        color: ${colors.gray};
    }
`;

export const FormButton = styled.button`
    background: ${colors.primary};
    color: ${colors.white};
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1.2rem;
    font-size: 1.25rem;
    cursor: pointer;

    &:hover {
        background: ${colors.hover.primary};
    }
    &:active {
        background: ${colors.active.primary};
    }
    &:focus {
        outline: none;
    }
    &:disabled {
        background: ${colors.disabled};
    }
`;

export const FormLink = styled(Link)<{ align: string }>`
    color: ${colors.secondary};
    font-size: 0.875rem;
    margin: 0.8rem 0;
    text-align: ${(props) => props.align};

    &:hover {
        text-decoration: none;
        color: ${colors.hover.secondary};
    }
`;
