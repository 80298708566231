import { to } from "../helpers";
import { User } from "../user/user_slice";
import { EntitiesResponse, EntityTypeResponse, ProjectResponse } from "./api_response_types";

export type UpdateEntityArgs = {
    descripcion: string;
    project: ProjectResponse;
    entity_type: EntityTypeResponse;
    //
    old_entity?: EntitiesResponse;
    close?: () => void;
};

class EntitiesApi {
    static async get_entity_api(tkn: string, user: User) {
        const params = new URLSearchParams();
        params.append("tkn", tkn);
        params.append("dni_enterprise", user.dni_enterprise);
        params.append("nu_tipo_entidad_doc_ent", user.nu_tipo_entidad_doc_ent);
        params.append("in_clasificacion_tipo_ent_doc_ent", user.in_clasificacion_tipo_ent_doc_ent);

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/entidades.php?${params.toString()}`, {
                method: "get"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const entities: EntitiesResponse[] = await response?.json();

        return entities;
    }

    static async get_entities_types_api(tkn: string) {
        const params = new URLSearchParams();
        params.append("tkn", tkn);
        params.append("consulta", "TipoEntidades");

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/entidades.php?${params.toString()}`, {
                method: "get"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const entities_types: EntityTypeResponse[] = await response?.json();
        return entities_types;
    }

    static async get_projects_api(tkn: string, user: User) {
        const params = new URLSearchParams();
        params.append("tkn", tkn);
        params.append("dni_enterprise", user.dni_enterprise);
        params.append("nu_tipo_entidad_doc_ent", user.nu_tipo_entidad_doc_ent);
        params.append("in_clasificacion_tipo_ent_doc_ent", user.in_clasificacion_tipo_ent_doc_ent);
        params.append("consulta", "Proyecto");

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/entidades.php?${params.toString()}`, {
                method: "get"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const projects: ProjectResponse[] = await response?.json();
        return projects;
    }

    static async get_entities_by_project_api(tkn: string, user: User, project: ProjectResponse) {
        const params = new URLSearchParams();
        params.append("tkn", tkn);
        params.append("consulta", "filtrar_por_proyecto");
        // Project data
        params.append("in_clasificacion_tipo_ent_pry", project.in_clasificacion_tipo_ent_pry);
        params.append("nu_tipo_entidad_pry", project.nu_tipo_entidad_pry);
        //
        params.append("dni_enterprise", user.dni_enterprise);
        params.append("nu_tipo_entidad_doc_ent", user.nu_tipo_entidad_doc_ent);
        params.append("in_clasificacion_tipo_ent_doc_ent", user.in_clasificacion_tipo_ent_doc_ent);

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/entidades.php?${params.toString()}`, {
                method: "get"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const entities: EntitiesResponse[] = await response?.json();

        return entities;
    }

    static async create_entity(tkn: string, user: User, args: UpdateEntityArgs) {
        const { descripcion, entity_type, project: project_type } = args;

        const data = new FormData();

        data.append("tkn", tkn);
        data.append("consulta", "Insertar");
        // entity
        data.append("nu_entidad", entity_type.nu_entidad);
        data.append("in_clasificacion_ent", entity_type.in_clasificacion_ent);
        data.append("descripcion", descripcion);
        // Project
        data.append("nu_tipo_entidad_pry", project_type.nu_tipo_entidad_pry);
        data.append("in_clasificacion_tipo_ent_pry", project_type.in_clasificacion_tipo_ent_pry);
        // User
        data.append("dni_enterprise", user.dni_enterprise);
        data.append("nu_tipo_entidad_doc_ent", user.nu_tipo_entidad_doc_ent);
        data.append("in_clasificacion_tipo_ent_doc", user.in_clasificacion_tipo_ent_doc_ent);
        data.append("in_clasificacion_tipo_ent_doc_ent", user.in_clasificacion_tipo_ent_doc_ent);

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/entidades.php`, {
                method: "post",
                body: data
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const entities: EntitiesResponse[] = await response?.json();

        return entities;
    }

    static async update_entity(tkn: string, user: User, args: UpdateEntityArgs) {
        const { descripcion, entity_type, project, old_entity } = args;
        const _old_entity = old_entity!;

        const data = new URLSearchParams();
        data.append("tkn", tkn);
        data.append("consulta", "Actualizar");
        // Entity
        data.append("descripcion", descripcion);
        data.append("nu_tipo_entidad", _old_entity?.nu_tipo_entidad);
        //
        data.append("in_clasificacion_tipo_ent", _old_entity.in_clasificacion_tipo_ent );
        data.append("nu_entidad", entity_type.nu_entidad);
        data.append("in_clasificacion_ent", entity_type.in_clasificacion_ent);
        //  Project
        data.append("nu_tipo_entidad_pry", project.nu_tipo_entidad_pry);
        data.append("in_clasificacion_tipo_ent_pry", project.in_clasificacion_tipo_ent_pry);
        // User
        data.append("dni_enterprise", user.dni_enterprise);
        data.append("nu_tipo_entidad_doc_ent", user.nu_tipo_entidad_doc_ent);
        data.append("in_clasificacion_tipo_ent_doc_ent", user.in_clasificacion_tipo_ent_doc_ent);

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/entidades.php?${data.toString()}`, {
                method: "PUT"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const entities: EntitiesResponse[] = await response?.json();

        return entities;
    }

    static async delete_entity(args: { tkn: string; user: User; entity: EntitiesResponse }) {
        const { entity, user } = args;

        const params = new URLSearchParams();
        params.append("tkn", args.tkn);
        params.append("consulta", "Eliminar");
        // entity
        params.append("nu_tipo_entidad", entity.nu_tipo_entidad);
        params.append("in_clasificacion_tipo_ent", entity.in_clasificacion_tipo_ent);
        params.append("nu_entidad", entity.nu_entidad);
        params.append("in_clasificacion_ent", entity.in_clasificacion_ent);
        //
        params.append("dni_enterprise", user.dni_enterprise);
        params.append("nu_tipo_entidad_doc_ent", user.nu_tipo_entidad_doc_ent);
        params.append("in_clasificacion_tipo_ent_doc_ent", user.in_clasificacion_tipo_ent_doc_ent);

        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/entidades.php?${params.toString()}`, {
                method: "DELETE"
            })
        );

        if (error) {
            console.error(error);
            return Promise.reject();
        }

        const entities: EntitiesResponse[] = await response?.json();

        return entities;
    }
}

export default EntitiesApi;
