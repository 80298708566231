import { AppNavbar, AppNavbarBrand } from "./styles";

const LoginNavbar = () => {
    return (
        <AppNavbar expand="lg" variant="dark">
            <AppNavbarBrand>Panel administrativo</AppNavbarBrand>
        </AppNavbar>
    );
};

export default LoginNavbar;
