import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";
import { RESET_STATE, useAppDispatch, useAppSelector } from "../../redux/redux";
import { LOGOUT } from "../../redux/user/user_login_action";
import UserAvatar from "../UserAvatar";

const LogoutOverlay = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user_slice.user);

    const _logoutPopover = useMemo(() => LogoutPopover(dispatch), [dispatch]);

    return (
        <OverlayTrigger trigger="click" placement="bottom" overlay={_logoutPopover}>
            <Button variant="outline-secondary" className="d-flex align-items-center ">
                <span className="text-light">{user?.nombre}</span>
                <UserAvatar size="42px" scale={2.2} />
            </Button>
        </OverlayTrigger>
    );
};

export default LogoutOverlay;

const LogoutPopover = (dispatch: any) => (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Popover right</Popover.Header>
        <Popover.Body>
            <Button
                variant="danger"
                onClick={() => {
                    dispatch(new RESET_STATE());
                    return dispatch(new LOGOUT());
                }}>
                Logout
            </Button>
        </Popover.Body>
    </Popover>
);
