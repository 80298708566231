import { faDashboard, faGear, faUsers, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled, { StyledComponent } from "styled-components";

type MenuItemProps = {
    isSidebarOpen: boolean;
    title: string;
    icon: IconDefinition;
    to: string;
    isSelected: boolean;
    onSelect: () => void;
};

const MenuItem: React.FC<MenuItemProps> = ({
    isSidebarOpen,
    icon,
    title,
    to,
    isSelected,
    onSelect
}) => {
    const navigate = useNavigate();

    return (
        <Button
            style={{
                textAlign: isSidebarOpen ? "left" : "center",
                height: "42px",
                backgroundColor: isSelected ? "rgba(0, 170, 248, 0.6)" : "",
                transition: '.5s',
                overflow: "hidden"
            }}
            variant="light"
            onClick={() => {
                onSelect();
                navigate(to);
            }}>
            <div>
                <FontAwesomeIcon icon={icon} />
                <div
                    style={{
                        display: "inline-block",
                        marginLeft: "0.5rem",
                        transform: isSidebarOpen ? "scaleX(1)" : "scaleX(0)",
                        transformOrigin: "left center",
                        transition: "0.2s"
                    }}>
                    {title}
                </div>
            </div>
        </Button>
    );
};

const SidebarDiv: StyledComponent<"div", any, { isSidebarOpen: boolean }, never> = styled.div`
    width: ${(props: any) => (props.isSidebarOpen ? "30%" : "64px")};
    overflow: hidden;
    display: flex;
    gap: 2px;
    padding-top: 12px;
    height: 100%;
    flex-direction: column;
    transition: 0.5s;
    transition-timing-function: ease-in-out;
    background-color: white;
`;

const MenuItemDivider = styled.hr`
    margin: 0;
    border-top: 1.5px solid;
`;

const MainSidebar: React.FC<{ isSidebarOpen: boolean }> = ({ isSidebarOpen }) => {
    const [selected, setSelected] = useState(1);

    return (
        <SidebarDiv isSidebarOpen={isSidebarOpen}>
            <MenuItemDivider />
            <MenuItem
                isSelected={selected === 1}
                onSelect={() => setSelected(1)}
                title="Dashboard"
                icon={faDashboard}
                to="/"
                isSidebarOpen={isSidebarOpen}
            />
            <MenuItemDivider />
            <MenuItem
                isSelected={selected === 2}
                onSelect={() => setSelected(2)}
                title="Entidades"
                icon={faGear}
                to="/entities"
                isSidebarOpen={isSidebarOpen}
            />
            <MenuItemDivider />
            <MenuItem
                isSelected={selected === 3}
                onSelect={() => setSelected(3)}
                title="Usuarios"
                icon={faUsers}
                to="/users"
                isSidebarOpen={isSidebarOpen}
            />
            <MenuItemDivider />
        </SidebarDiv>
    );
};

export default MainSidebar;
