import produce from "immer";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { AppAction } from "../action_template";
import { GET_ENTITY_REQUEST } from "../entity_slice/entity_get_action";
import { PromiseStatus, sleep, to } from "../helpers";
import { default_state, RootState } from "../redux";
import { User } from "./user_slice";

type LoginRequestPayload = {
    email: string;
    password: string;
};

// LOGIN FLOW

export class LOGIN_REQUEST extends AppAction<LoginRequestPayload> {
    type = "LOGIN_REQUEST";

    constructor(payload: LoginRequestPayload) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.user_slice.auth_request_status = PromiseStatus.pending;
        });
    }

    /**
     * Function called when an login request is made
     * @param action {LOGIN_REQUEST}
     */
    static *login_saga(action: LOGIN_REQUEST): Generator {
        const payload = action.payload!;
        const [response, error]: any = yield call(() =>
            to(LOGIN_REQUEST.apiCall(payload.email, payload.password))
        );
        yield put(new LOGIN_REQUEST_FINISH());

        if (error) {
            toast.error(error);
            return yield put(new LOGIN_ERROR(error));
        }

        // Verify that the current user has administrative privileges
        // and return a login error.
        const user = response.user as User;
        if (user.nu_tipo_entidad_prf === "3") {
            toast.error("Usted no tiene permisos administrativos.");
            return yield put(new LOGIN_ERROR(error));
        }

        toast.success("Autenticación exitosa");
        yield put(new LOGIN_SUCCESS(response));
        yield put(new GET_ENTITY_REQUEST());
    }

    static async apiCall(
        email: string,
        password: string
    ): Promise<{ auth_id: string; user: User }> {
        const formData = new FormData();
        formData.append("correo", email);
        formData.append("password", password);

        // tonytarco@hotmail.com:v23166229
        const [response, error] = await to(
            fetch(`${process.env.REACT_APP_API_URL}/usuarios.php`, {
                method: "post",
                body: formData
            })
        );

        if (error) return Promise.reject("Hubo un error de red, por favor intente mas tarde.");

        const values = await response?.json();

        if (!values.token) return Promise.reject(values.mensaje);

        return {
            auth_id: values.token,
            user: values.usuario
        };
    }
}

type LoginSuccessPayload = { auth_id: string; user: User };
class LOGIN_SUCCESS extends AppAction<LoginSuccessPayload> {
    type = "LOGIN_SUCCESS";

    constructor(payload: LoginSuccessPayload) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        const _payload = this.payload!;

        return produce(state, (draft) => {
            draft.user_slice.auth_request_status = PromiseStatus.success;
            draft.user_slice.auth_id = _payload.auth_id;
            draft.user_slice.user = {
                nombre: _payload.user.nombre,
                correo: _payload.user.correo,
                dni_enterprise: _payload.user.dni_enterprise,
                in_clasificacion_tipo_ent_doc_ent: _payload.user.in_clasificacion_tipo_ent_doc_ent,
                nu_tipo_entidad_doc_ent: _payload.user.nu_tipo_entidad_doc_ent,
                nu_tipo_entidad_prf: _payload.user.nu_tipo_entidad_prf,
                role: { "1": "superAdmin", "2": "admin", "3": "user" }[
                    _payload.user.nu_tipo_entidad_prf
                ] as any,
                fecha_exp_lic: _payload.user.fecha_exp_lic
            };
        });
    }
}

class LOGIN_ERROR extends AppAction<string> {
    type = "LOGIN_ERROR";

    constructor(payload: string) {
        super();
        this.payload = payload;
    }

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.user_slice.auth_request_status = PromiseStatus.error;
        });
    }
}

class LOGIN_REQUEST_FINISH extends AppAction<void> {
    type = "LOGIN_REQUEST_FINISH";

    reduce(state: RootState): RootState {
        return produce(state, (draft) => {
            draft.user_slice.auth_request_status = PromiseStatus.idle;
        });
    }
}

export class LOGOUT extends AppAction<void> {
    type = "LOGOUT";

    reduce(): RootState {
        return default_state;
    }
}
